<template>
  <span>
    <page-header
      icon="fa-solid fa-people-group"
      title="Groupes d'âges personnalisés"
      :links="getLinks()"
    ></page-header>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div v-if="!isLoading(loadingName)">
      <div>
        <x-table
          :columns="columns"
          :items="rows"
          :show-counter="true"
          verbose-name="enfant"
          @selectionChanged="onSelectionChanged($event)"
        ></x-table>
      </div>
    </div>
    <confirm-modal
      name="reset-custom-age-group"
      title="Groupes d'âge personnalisés"
      :text="confirmText"
      @confirmed="resetAgeGroups"
    >
    </confirm-modal>
  </span>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif'
import XTable from '@/components/Controls/Table/Table'
import PageHeader from '@/components/Layout/PageHeader'
import ConfirmModal from '@/components/Modals/ConfirmModal'
import { dateToString } from '@/filters/texts'
import { BackendMixin } from '@/mixins/backend'
import router from '@/router'
import { makeChild } from '@/types/families'
import { makeIndividual } from '@/types/people'
import { getAgeValue } from '@/utils/dates'
import { BackendApi } from '@/utils/http'

export default {
  name: 'ChildrenWithCustomAgeGroups',
  mixins: [BackendMixin],
  components: {
    ConfirmModal,
    PageHeader,
    LoadingGif,
    XTable,
  },
  props: {
  },
  data() {
    return {
      loadingName: 'ChildrenWithCustomAgeGroups',
      items: [],
      columns: [],
      selectedChildIds: [],
    }
  },
  mounted() {
    this.columns = this.getColumns()
    this.loadList()
  },
  computed: {
    rows() {
      return this.items.map(
        elt => this.makeRow(elt)
      )
    },
    confirmText() {
      if (this.selectedChildIds.length > 1) {
        return 'Supprimer les groupes d\'âges personnalisés des ' + this.selectedChildIds.length + ' enfants sélectionnés'
      } else {
        return 'Supprimer le groupe d\'âge personnalisé de l\'enfant sélectionné'
      }
    },
    canChangeChildren() {
      return this.hasPerm('families.change_child')
    },
  },
  methods: {
    ...mapActions(['addSuccess', 'addError', 'addWarning']),
    ...mapMutations(['startLoading', 'endLoading']),
    getColumns() {
      let columns = []
      if (this.canChangeChildren) {
        columns = [{ selector: true, 'name': 'selector', }]
      }
      return columns.concat([
        {
          name: 'lastName',
          label: 'Nom',
          onClick: item => {
            this.showEntitySidebar(item.entity)
          },
          isLink: item => {
            return (item.entityId)
          },
          linkUrl: item => {
            if (item.entityId) {
              return router.resolve({ name: 'families-detail', params: { entityId: item.entityId, }, }).href
            }
          },
        },
        {
          name: 'firstName',
          label: 'Prénom',
          onClick: item => {
            this.showIndividualSidebar(item.individual)
          },
          isLink: item => {
            return (item.entityId)
          },
          linkUrl: item => {
            if (item.entityId) {
              return router.resolve({ name: 'families-detail', params: { entityId: item.entityId, }, }).href
            }
          },
        },
        {
          name: 'birthDate',
          label: 'Naissance',
          dateFormat: 'LL',
        },
        {
          name: 'age',
          label: 'Âge',
          number: true,
        },
        {
          name: 'schoolClass',
          label: 'Classe',
        },
        {
          name: 'school',
          label: 'École',
        },
        {
          name: 'schoolLevel',
          label: 'Niveau',
        },
        {
          name: 'ageGroup',
          label: 'Groupe',
          number: true,
        },
        {
          name: 'ageGroupFrom',
          label: 'Séances du',
          dateFormat: 'DD/MM/YYYY',
        },
        {
          name: 'ageGroupTo',
          label: 'au',
          dateFormat: 'DD/MM/YYYY',
        }
      ])
    },
    getLinks() {
      if (this.canChangeChildren) {
        return [
          {
            id: 1,
            label: 'Réinitialiser',
            callback: this.onReset,
            icon: 'fa fa-undo',
            cssClass: (
              this.isLoading(this.loadingName) || (this.selectedChildIds.length === 0)
            ) ? 'btn-secondary disabled' : 'btn-secondary',
          }
        ]
      }
    },
    async resetAgeGroups() {
      let url = '/api/custom-age-groups/'
      const backendApi = new BackendApi('post', url)
      const data = {
        'children': this.selectedChildIds,
      }
      try {
        const resp = await backendApi.callApi(data)
        await this.addSuccess(resp.data.message)
        this.$bvModal.hide('bv-modal-add-comment-tag')
        await this.loadList()
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    onReset() {
      this.$bvModal.show('bv-confirm-modal:reset-custom-age-group')
    },
    async loadList() {
      this.startLoading(this.loadingName)
      let url = '/api/custom-age-groups/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.items = resp.data.map(
          elt => {
            return {
              individual: makeIndividual(elt.individual),
              child: makeChild(elt.child),
            }
          }
        )
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    makeRow(elt) {
      const entity = (elt.individual.entities.length > 0) ? elt.individual.entities[0] : null
      const entityId = entity ? entity.id : 0
      return {
        id: elt.child.id,
        entity: entity,
        lastName: elt.individual.lastName,
        firstName: elt.individual.firstName,
        birthDate: dateToString(elt.individual.birthDate, 'YYYY-MM-DD'),
        age: getAgeValue(elt.individual.birthDate),
        ageGroup: elt.child.ageGroup,
        individualId: elt.individual.id,
        individual: elt.individual,
        entityId: entityId,
        ageGroupFrom: elt.child.ageGroupFrom ? dateToString(elt.child.ageGroupFrom, 'YYYY-MM-DD') : '',
        ageGroupTo: elt.child.ageGroupTo ? dateToString(elt.child.ageGroupTo, 'YYYY-MM-DD') : '',
        schoolClass: elt.child.schoolClass ? elt.child.schoolClass.name : '',
        school: (elt.child.schoolClass && elt.child.schoolClass.school) ? elt.child.schoolClass.school.name : '',
        schoolLevel: elt.child.schoolLevel ? elt.child.schoolLevel.name : '',
      }
    },
    onSelectionChanged(event) {
      this.selectedChildIds = event.items.map(elt => elt.id)
    },
  },
}
</script>

<style scoped>
</style>
